import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PagamentoLamina, ItemLamina, Laminas } from '../model';
import { Observable } from 'rxjs';
import { API } from '../app.config';

@Injectable()
export class LaminasService {

  constructor(private http: HttpClient) { }

  gerarImagemHTML(ItemLamina: ItemLamina, pagamentoLamina: PagamentoLamina) {

    ItemLamina.Pagamentos = pagamentoLamina;
    ItemLamina.Pagamentos.SimboloCambio = pagamentoLamina.CambioPagamento.Simbolo;

    const headers = new HttpHeaders().append('noloader', 'noloader');
    return this.http.post<any>(`${API}/api/laminas/generateImage`, ItemLamina, { headers: headers })
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/images/${id}`);
  }

  formasPagamento(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/pagamentosDisponiveis/${id}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/laminas/images/delete/${id}`);
  }

  editar(objetoClass: Laminas): Observable<any> {
    return this.http.put<any>(`${API}/api/laminas/images/edit/${objetoClass.Id}`, objetoClass);
  }

  buscarLaminas(): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/quantidadelaminasgeradas`);
  }

  uploadArquivo(arquivo: any = null, objetoClass: Laminas): Observable<any> {
    let transicao = JSON.stringify(objetoClass);
    const url = `${API}/api/laminas/images/upload`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Dados', transicao);
    return this.http.post(url, formData, { headers: headers });
  }

  ListaPorParametro(param: string): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/laminas/images/lista/${param.trim()}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/laminas/images/lista`, { headers: headers });
    }
  }

  ListaPorParametroPaginada(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/laminas/images/listaPaginada/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/laminas/images/listaPaginada?Pagina=${pagina}&ItensPorPagina=${9}`, { headers: headers });
    }
  }

  ListaTemplatePaginada(pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/laminas/templates/listaPaginada?Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
  }

  ListarImagensUnsplash(parametro: string, pagina: Number) {
    const API_URL_UNSPLASH = "https://api.unsplash.com/photos/random";
    const ACESSKEY_UNSPLASH = "LU8o5gKtEmrB6D2D1-tE6vdwAmZYfKE9GOG5wWd2-j8";


    let params = new HttpParams();
    params = params.append('count', "12");
    params = params.append('query', parametro);
    params = params.append('client_id', ACESSKEY_UNSPLASH);

    return this.http.get<any>(API_URL_UNSPLASH, {
      params: params,
    });

    //PEXELS
    // const API_URL_PEXELS = `https://api.pexels.com/v1/search?query=${parametro}&per_page=8&width=1080&height=1920`;
    // const ACESSKEY_PEXELS = "L0OHyQoeGI14oJewjPq5cjew8f13X9S3A8X0gL7kmbUI5WunT902n2Nk";
    // const headers = new HttpHeaders().set('Authorization', ACESSKEY_PEXELS);
    // var headers = {
    //   "Authorization": ACESSKEY_PEXELS
    // }
    // return this.http.get<any>(API_URL_PEXELS, { headers: headers });
  }

  // ListarImagensSourceUnsplash(parametro: string): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

  //   if (parametro.length == 0) {
  //     parametro = "airplane";
  //   };

  //   return this.http.get(`https://source.unsplash.com/1080x1920/?${parametro}`, { headers, responseType: 'text' });
  // }
}
